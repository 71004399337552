// Generated by Framer (838580a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["aVnlbRAMg", "KvwdWTSQ2", "GivCyV5RU"];

const serializationHash = "framer-ETyjX"

const variantClassNames = {aVnlbRAMg: "framer-v-xnn0lv", GivCyV5RU: "framer-v-t8p4j5", KvwdWTSQ2: "framer-v-1dtlhon"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"1": "aVnlbRAMg", "2": "KvwdWTSQ2", "3": "GivCyV5RU"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "aVnlbRAMg"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "aVnlbRAMg", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fit", pixelHeight: 1560, pixelWidth: 1300, positionX: "center", positionY: "center", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/fPXnplrmlsfOIcY1FssIsbjVw7Y.png", srcSet: "https://framerusercontent.com/images/fPXnplrmlsfOIcY1FssIsbjVw7Y.png?scale-down-to=1024 853w,https://framerusercontent.com/images/fPXnplrmlsfOIcY1FssIsbjVw7Y.png 1300w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-xnn0lv", className, classNames)} data-framer-name={"1"} layoutDependency={layoutDependency} layoutId={"aVnlbRAMg"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}} {...addPropertyOverrides({GivCyV5RU: {"data-framer-name": "3", background: {alt: "", fit: "fit", pixelHeight: 1560, pixelWidth: 1300, positionX: "center", positionY: "center", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/mPLhfRO6WxQ4ZhjWehczxuS16zI.png", srcSet: "https://framerusercontent.com/images/mPLhfRO6WxQ4ZhjWehczxuS16zI.png?scale-down-to=1024 853w,https://framerusercontent.com/images/mPLhfRO6WxQ4ZhjWehczxuS16zI.png 1300w"}}, KvwdWTSQ2: {"data-framer-name": "2", background: {alt: "", fit: "fit", pixelHeight: 1560, pixelWidth: 1300, positionX: "center", positionY: "center", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/7C1mhihkPCoVnnqJD7mKL1qwTY.png", srcSet: "https://framerusercontent.com/images/7C1mhihkPCoVnnqJD7mKL1qwTY.png?scale-down-to=1024 853w,https://framerusercontent.com/images/7C1mhihkPCoVnnqJD7mKL1qwTY.png 1300w"}}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ETyjX.framer-dmwkqk, .framer-ETyjX .framer-dmwkqk { display: block; }", ".framer-ETyjX.framer-xnn0lv { height: 530px; overflow: visible; position: relative; width: 450px; }", ".framer-ETyjX.framer-v-1dtlhon.framer-xnn0lv, .framer-ETyjX.framer-v-t8p4j5.framer-xnn0lv { aspect-ratio: 0.8492569002123143 / 1; height: var(--framer-aspect-ratio-supported, 530px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 530
 * @framerIntrinsicWidth 450
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"KvwdWTSQ2":{"layout":["fixed","fixed"]},"GivCyV5RU":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerGbapQTWt9: React.ComponentType<Props> = withCSS(Component, css, "framer-ETyjX") as typeof Component;
export default FramerGbapQTWt9;

FramerGbapQTWt9.displayName = "Features Images";

FramerGbapQTWt9.defaultProps = {height: 530, width: 450};

addPropertyControls(FramerGbapQTWt9, {variant: {options: ["aVnlbRAMg", "KvwdWTSQ2", "GivCyV5RU"], optionTitles: ["1", "2", "3"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerGbapQTWt9, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})